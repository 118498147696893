<template>
    <b-modal id="barang-modal" size="xl" :no-close-on-backdrop="true" :no-close-on-esc="true">
        <header class="mb-1">
            <h5>Barang dipilih ({{ checkedBarangs.length }}).</h5>
            <b-form-group label="Filter">
                <b-input-group size="sm">
                    <b-form-input
                        id="filterInput"
                        v-model="filter"
                        type="search"
                        placeholder="Type to Search"
                    />
                    <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
        </header>
        <validation-observer ref="stok">
            <b-table :fields="fields" :filter="filter" :items="localBarangs" responsive>
                <template #head(checkbox)>
                    <b-form-checkbox v-model="allChecked"></b-form-checkbox>
                </template>
                <template #cell(checkbox)="{item}">
                    <b-form-checkbox v-model="item.checked" :checked="item.checked ? 'checked' : ''"></b-form-checkbox>
                </template>
                <template #cell(nama)="{item}">
                    <span v-if="item.barang">{{ item.barang.nama }}</span>
                    <i class="text-danger" v-else>Barang tidak ditemukan</i>
                </template>
                <template #cell(lokasi)="{item}">
                    {{ getLokasi(item) }}
                </template>
                <template #cell(qty)="{item, index}">
                    <validation-provider
                        #default="{ errors }"
                        name="qty"
                        :rules="{ max_value: item.stok }"
                    >
                        <b-form-input 
                            type="number"  
                            v-model="item.qty"
                            @keyup="onQtyChange(index)"
                            :state="errors.length > 0 ? false : null"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </template>
                <template #cell(satuan)="{item, index}">
                    <!-- <v-select v-if="item.barang && item.barang.konversi" @change="onKonversiChange(index)" v-model="item.selectedKonversi" :options="getKonversiOption(item)" label="text" ></v-select> -->
                    <b-form-select v-if="item.barang && item.barang.konversi" v-model="item.selectedKonversi" :options="getKonversiOption(item)" @change="onKonversiChange(index)" />
                    <span v-else-if="item.barang && item.barang.satuan">
                        {{ item.barang.satuan.satuan }}
                    </span>
                    <i class="text-danger" v-else>Satuan tidak ditemukan</i>
                </template>
                <template #cell(converted)="{item}">
                    {{ item.converted ? `${item.converted} /${item.barang && item.barang.satuan ? item.barang.satuan.satuan : '-'}` : `0 /${item.barang && item.barang.satuan ? item.barang.satuan.satuan : '-'}` }}
                </template>
            </b-table>
        </validation-observer>
        <template #modal-footer>
            <b-button @click.prevent="submit" variant="primary">Pilih Barang</b-button>
        </template>
    </b-modal>    
</template>
<script>
import {BModal, BFormGroup, BInputGroup, BFormCheckbox, BFormInput, BFormSelect, BInputGroupAppend, BButton, BTable} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max_value } from "@validations";
import vSelect from 'vue-select'
export default {
    props: ['barangs'],
    components: {
        BModal, BFormGroup, BInputGroup, BFormCheckbox, BFormInput, BFormSelect, BInputGroupAppend, BButton, BTable,
        ValidationProvider, ValidationObserver, vSelect
    },
    computed: {
        checkedBarangs() {
            return this.localBarangs.filter(item => item.checked)
        }
    },
    data: () => ({
        required,
        max_value,
        allChecked: false,
        selectedBarangs: [],
        filter: null,
        fields: [
            { key: 'checkbox', label: '#' },
            { key: 'nama', label: 'Nama Barang' },
            { key: 'lokasi', label: 'Lokasi Barang' },
            { key: 'stok', label: 'Stok' },
            { key: 'qty', label: 'Qty' },
            { key: 'satuan', label: 'Satuan' },
            { key: 'converted', label: 'Hasil Konversi' }
        ],
        localBarangs: []
    }),
    methods: {
        async onKonversiChange(index) {
            const localBarangs = Object.assign([], this.localBarangs)
            if(localBarangs[index].qty) {
                const konversi = localBarangs[index].selectedKonversi
                const payload = {
                    tipe: 2, // dari terbesar ke terkecil
                    id_barang: konversi.id_barang,
                    id_satuan: konversi.id_satuan,
                    jumlah: localBarangs[index].qty
                }

                const hasilKonversi = await this.$store.dispatch('konversi/konversiBarang', payload)
                localBarangs[index].converted = hasilKonversi
                this.localBarangs = localBarangs
            }
        },
        async onQtyChange(index) {
            if(this.localBarangs[index].selectedKonversi && this.localBarangs[index].qty > 0) {
                const localBarangs = Object.assign([], this.localBarangs)
                const konversi = localBarangs[index].selectedKonversi
                const payload = {
                    tipe: 2, // dari terbesar ke terkecil
                    id_barang: konversi.id_barang,
                    id_satuan: konversi.id_satuan,
                    jumlah: localBarangs[index].qty
                }

                const hasilKonversi = await this.$store.dispatch('konversi/konversiBarang', payload)
                localBarangs[index].converted = hasilKonversi
                this.localBarangs = localBarangs
            }
        },
        getKonversiOption({qty, barang}, index) {
            if(barang.konversi) {
                const options = barang.konversi.map(konv => ({
                    text: konv.satuan.satuan,
                    value: konv,
                    id_barang: konv.id_barang,
                    id_satuan: konv.id_satuan,
                    satuan: konv.satuan
                }))
                
                return options
            }
            // konversi/konversiBarang
        },
        getLokasi(item) {
            let lokasi = []
            if(item.blok) lokasi.push(`Blok: ${item.blok.blok}`)
            if(item.palet) lokasi.push(`Palet: ${item.palet.palet}`)
            if(item.rak) lokasi.push(`Rak: ${item.rak.rak}`)
            if(item.laci) lokasi.push(`Laci: ${item.laci.laci}`)

            return lokasi.join(', ')
        },
        doFormatRupiahHB(index) {
            const barangs = Object.assign([], this.localBarangs)
            barangs[index].harga_beli = this.formatRupiah(barangs[index].harga_beli)
            // update reactivity
            this.localBarangs = barangs
        },
        doFormatRupiahHDB(index) {
            const barangs = Object.assign([], this.localBarangs)
            barangs[index].harga_dasar_baru = this.formatRupiah(barangs[index].harga_dasar_baru)
            // update reactivity
            this.localBarangs = barangs
        },
        doFormatRupiahDiskon(index) {
            const barangs = Object.assign([], this.localBarangs)
            barangs[index].diskon = this.formatRupiah(barangs[index].diskon)
            // update reactivity
            this.localBarangs = barangs
        },
        
        submit() {
            if(this.checkedBarangs.length < 1) {
                this.displayError({
                    message: 'Harap pilih barang!'
                })
                return false
            }

            const thereZeroQty = this.checkedBarangs.some(barang => parseInt(barang.qty) < 1 || !barang.qty || barang.qty == '')
            if(thereZeroQty) {
                this.displayError({
                    message: 'Kuantitas barang yang dipilih harus melebihi 0!'
                })
                return false
            }

            const thereOverStok = this.checkedBarangs.some(barang => barang.converted ? parseInt(barang.converted) > parseInt(barang.stok) : parseInt(barang.qty) > parseInt(barang.stok))
            if(thereOverStok) {
                this.displayError({
                    message: 'Kuantitas barang tidak boleh melebihi stok yang ada!'
                })
                return false
            }

            this.checkedBarangs.map(item => {
                if(item.converted) item.qty = parseInt(item.converted)
            })

            this.$emit('submit', this.checkedBarangs)
        }
    },
    watch: {
        allChecked(val) {
            if(val) {
                const localBarangs = Object.assign([], this.localBarangs)
                localBarangs.map(item => {
                    item.checked = true
                })
                setTimeout(() => this.localBarangs = localBarangs, 1000)
                
            }
            else {
                const localBarangs = Object.assign([], this.localBarangs)
                localBarangs.map(item => {
                    item.checked = false
                })
                setTimeout(() => this.localBarangs = localBarangs, 1000)
            }

        },
        barangs(val) {
            if(val) {
                this.localBarangs = JSON.parse(JSON.stringify(val))
                this.localBarangs.map(barang => {
                    barang.checked = false
                    barang.selectedKonversi = null // untuk watch
                    barang.converted = null // untuk display hasil konversi
                })
            }
        }
    }
}
</script>